import styled from '@emotion/styled';

import logo from '../../../../static/logo.png';

export default styled.div`
  width: 160px;
  height: 36px;
  background-size: contain;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: left;
`;